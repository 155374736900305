@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

/* @font-face {
  font-family: 'YourFont';
  src: url('../fonts/your-font.woff2') format('woff2');
  font-display: swap;
} */


body {
  margin: 0;
  font-family:Montserrat, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f8fa;
}

::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track{
  background-color: #E6E6E6;
}

::-webkit-scrollbar-thumb{
  background-color: #C6C8CA;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover{
  background: #0B72B5;
}

